<template>
  <div
    class="payment-method"
    :class="
      method === account.id || method === 'new-account'
        ? 'border-invest-purple'
        : 'border-invest-gray'
    "
  >
    <div class="inline-flex items-center">
      <div
        class="flex items-center justify-center w-6 h-6 rounded-full"
        :class="
          method === account.id || method === 'new-account'
            ? 'bg-invest-purple'
            : 'border-1 bg-gray-100'
        "
      >
        <ion-icon
          v-if="method === account.id || method === 'new-account'"
          name="checkmark-outline"
          class="text-gray-100"
        ></ion-icon>
      </div>
      <slot>
        <div class="flex flex-col ml-4">
          <h2 class="mt-1 text-xs font-semibold text-invest-darkblue">
            {{ account.account_name }}
          </h2>
          <p class="mt-1 mb-1 text-10 text-invest-darkblue text-opacity-80">
            {{ account.account_number }} • {{ getBankName(account.bank_name) }}
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AccountProp } from '@/types/Account';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    account: {
      type: Object as PropType<AccountProp>,
      default: () => ({} as AccountProp),
    },
    method: {
      type: String,
      default: '',
    },
  },
  setup: () => {
    const { state } = useStore();

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    return { getBankName };
  },
});
</script>
