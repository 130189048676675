
import { defineComponent, PropType } from 'vue';
import { AccountProp } from '@/types/Account';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    account: {
      type: Object as PropType<AccountProp>,
      default: () => ({} as AccountProp),
    },
    method: {
      type: String,
      default: '',
    },
  },
  setup: () => {
    const { state } = useStore();

    const getBankName = (bankCode: string): string => {
      return state?.utils?.banks?.find((bank: any) => bank?.code === bankCode)
        ?.name;
    };

    return { getBankName };
  },
});
